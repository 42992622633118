/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "When I joined Konrad and Techvibes, a stock WordPress template was in use that had many unused plugins and tons of unused code. After this rebuild, two plugins were in use, only code in use was running, and it scored 98 on Google PageSpeed insights. The whole project has an editorial site where internal and external authors can post; A job board where companies have profiles and job postings; And a custom admin portal to manage the companies on the job board, manage event ticket purchases and customize job alert algorithms."), "\n", React.createElement(_components.p, null, "On the editorial site, several custom features made it work well on all devices. The homepage has an adaptive layout. It is fully responsive, but when loaded on a touch device, slider functionality is improved, and content is laid out slightly differently to make it easier to use. Initially, the homepage had ~85 database queries to load and render the content. I created a custom caching plugin which served static files and saw a massive boost in page load performance."), "\n", React.createElement(_components.p, null, "For all the sites, I worked closely with a designer to develop and enforce styles, pixel-push the design until it was perfect, and work on treatments of all the imagery. All in all, about ten templates across a WordPress and a PHP site look and work together seamlessly."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
